import schttp from 'public/src/services/schttp'
import { timeZone } from '../utils/index'

// 个人中心第一部分接口
export const queryFirstPartInfoApi = () =>
  schttp({
    method: 'GET',
    url: `/user/center/first_part_info`,
    data: {
      timezone: timeZone(),
      blackbox: ''
    },
    useBffApi: true
  })

// 个人中心第二部分
export const querySecondPartInfoApi = () =>
  schttp({
    method: 'GET',
    url: `/user/center/second_part_info`,
    data: {
      timezone: timeZone(),
      blackbox: ''
    },
    useBffApi: true
  })

// 获取文章页轮播数据
export const queryNewsApi = () =>
  schttp({
    method: 'GET',
    url: '/ccc/common_component?cccPageType=personalCenterNewsPage',
    data: { timezone: timeZone() },
    useBffApi: true
  })

export const queryUnreadTicketNum = () =>
  schttp({
    method: 'GET',
    url: `/ticket/unread_ticket_num`,
    useBffApi: true
  })

export const queryUnreadMessageNum = () =>
  schttp({
    method: 'GET',
    url: `/user/v3/msg_unread?_ver=1.1.8&_lang=en`,
    useBffApi: true
  })

export const queryWishAndFollowList = () =>
  schttp({
    method: 'POST',
    url: `/user/center/show_store_wish_list`,
    useBffApi: true
  })

export const queryRecFollowList = () =>
  schttp({
    method: 'GET',
    url: `/product/recommend/store_list`,
    data: { page: 1, limit: 9 },
    useBffApi: true
  })

export const queryOrderList = () =>
  schttp({
    method: 'GET',
    url: `/user/center/getOrderList?page=1&limit=2&filterType=1`,
    data: { shouldCheckBlackList: 1 },
    useBffApi: true
  })
