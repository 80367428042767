// 详情页abt配置

export default {
  pageName: 'page_goods_detail',
  newPosKeys: [
    'CccDetailVideo', 
    'soldoutsimilar', 
    'similaritemsnew', 
    'OutfitRecommendEntry',
    'OutfitRecommendLand',
    'recSwitch',
    'EstimatedPrice',
    'Promotionrankdetial',
    'paydiscountshowdetail',
    'Qualityfloor',
    'wishsimilar',
    'addbagsuccess',
    'EstimatedShowType',
    'Quickinterestpoint',
    'storeflash',
    'storeccc',
    'NewCoupon',
    'addbagpopup',
    'addnewhotJson',
    'addbagpopupcates',
    'addbagpopupgoods',
    'listgoodsCard',
    'sixmore',
    'ymalrecommend',
    'detailgoodsCard',
    'discountLabel',
    'greysellingPoint',
    'listflashSale',
    'DetailStarReview',
    'FrequentBoughtTogether',
    'detailKeyAttribute',
    'listdiscountLabel',
    'pclistnewCard',
    'pcwordSize',
    'FlashSaleRecItemShop',
    'buytogether',
    'Structureoptiz',
    'listattributeLabels',
    'listtagSorting',
    'listrankingTag',
    'detailcountdown',
    'BuyBoxSwitch',
    'collestrategy',
    'listquickship', // Quickship标签优化
    'listquickshipKey', // Quickship标签优化
    'newoutfit',
    'componentswitch', // 找相似cccx推荐
    'localMall', // 商卡abt 前端展示库存文案
    'listwithCoupon' // 商卡到手价样式
  ],
  posKeys: [
    'Shippingtime',
    // 'Reviewsort',
    'CccGoodsdetail',
    'CccGoodsDetails',
    'Reviewlabel',
    'SellingPoint',
    'ViewMoreButton',
    'shipping',
    // 'BeautyFindation',
    'NoSkuClick',
    'FreeShipping',
    'GoodsdetailToppicOutfitRecommend',
    'DetailPageQuickShipShow',
    'localwarehouse',
    // 'ShowVariedLabel',
    'CustomersAlsoViewedQuickAddBag',
    // 'YouMayAlsoLikeUserAct',
  ],
}
