/***
 * !!! 当前文件在服务端可能会执行 --- SSR
 * 请谨慎引入文件及注意window变量的使用
 */
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { getBiCccSourceFrom, getGaCccParams } from './ccc-analysis-utils'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

const { langPath = '', SW_FETCH_HTML_CONFIG = {} } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

// 需要添加styleType的组件 map
const STYLE_TYPE_MAP = {
  ONE_THIRD_IMAGE: 'ONE_THIRD_IMAGE',
  ONE_THIRD_IMAGE_CAROUSEL: 'ONE_THIRD_IMAGE_CAROUSEL',
  ONE_THIRD_ITEM: 'ONE_THIRD_ITEM'
}
// sbc 和 h1 板块标识符(all 和 非all)
const blockIdentifiers = {
  'h1': ['pch1'],
  'sbc': ['pcsbc']
}
/**
 * 生成 CCC 链接对象
 */
export class CccLink {
  /**
   * @param {Object} sceneData  频道的场景数据配置
   *   @param {String} sceneName       场景名称
   *   @param {String} poskey          当前频道下的实验poskey，多个请用逗号拼接
   *
   * propData: 应该是类似这样的一个数据结构 wiki.pageId=883165929
   * @param {*} propData 组件的ccc配置
   */
  constructor({ sceneData, propData, context }) {
    this.sceneData = sceneData
    this.propData = propData
    this.context = context
  }

  /**
   * 获取CCC 的纯跳转跳转链接
   * 只会依赖传入的参数
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} cateLinks CCC 特殊链接的数据
   */
  getLink({ item = {}, cateLinks = {} }) {
    let link = 'javascript:;'
    const itemHrefType = item.hrefType
    const itemHrefTarget = item.hrefTarget

    switch (itemHrefType) {
      // 不跳转
      case 'noJump':
        link = 'javascript:;'
        break
      // Web 链接
      case 'webLink':
        // isInternalLink
        if (item.isInternalLink == 1) {
          // 内部链接
          link = `${langPath}${itemHrefTarget}`
        } else {
          // 外部链接
          link = itemHrefTarget
        }
        break
      case 'skuList':
      case 'sku':
      case 'real':
      case 'frontCat':
      case 'itemPicking':
        {
          const attrKey = itemHrefType === 'skuList' ? 'sku' : itemHrefType
          let cateLink = cateLinks[`${attrKey}_${itemHrefTarget}`] || ''
          if (cateLink.url)
            cateLink = cateLink.url
          const urlLink = cateLink || item.relativeUrl
          if (urlLink) {
            link = `${langPath}${urlLink}`
          }
        }
        break
      // 新专题 （专题新CCC数据路由）
      case 'activityNew':
        link = `${langPath}/campaigns/${itemHrefTarget}`
        // 检查是否为一级路径
        try {
          const pathIdentityMapInfo = this.context?.content?.pathIdentityMapInfo || []
          const path = pathIdentityMapInfo.filter(i => i.identity === itemHrefTarget)[0]?.tinyUrl
          if (path) {
            link = `${langPath}/${path}`
          }
        } catch(e) {
          console.error(e)
        }
        break
      // 原专题 （专题旧CCC数据路由）
      case 'activityOld':
        link = `${langPath}/campaign/${itemHrefTarget}`
        break
      case 'flashSale': // [shein]
      case 'lightningDeal': // [rowme]
        link = `${langPath}/flash-sale.html`
        break
      // [shein]
      case 'sheinPicks':
        try {
          const PICKS_LINKS = this.context?.PICKS_GLOBAL_INFO?.LINKS || {}
          link = transformPromodiscountUrl(
            `${langPath}/shein-picks.html?hrefTarget=${itemHrefTarget}`,
            PICKS_LINKS
          )
        } catch(e) {
          console.log(e)
        }
        break
      case 'dailyNew':
        link = `${langPath}/daily-new.html`
        break
      // 问卷调查
      case 'survey':
        link = `${langPath}/survey/${itemHrefTarget}`
        break
      // 试用中心
      case 'trial':
        {
          const urlLink = cateLinks[itemHrefType] || item.webClickUrl
          if (urlLink) {
            link = `${langPath}${urlLink}`
          }
        }
        break
      // 试用列表
      case 'lookBook':
        link = `${langPath}/shop-multiple-sets-${itemHrefTarget}.html`
        break
      // 文章页
      case 'article':
        {
          if (item.relativeUrl) {
            link = `${langPath}/${item.relativeUrl}.html`
          } else {
            const linkName = item.selectTypeName || 'article'
            link = `${langPath}/${linkName}-a-${itemHrefTarget}.html`
          }
        }
        break
      // 游戏
      case 'game':
        link = item.gameType
          ? `${langPath}/game/${item.gameType}/${itemHrefTarget}`
          : 'javascript:;'
        break
      // 第三方店铺列表 [shein]
      case 'thirdPartyStoreList':
      case 'thirdPartyStoreHome':
        {
          let storeLink = cateLinks[`store_${itemHrefTarget}`] || item.webClickUrl
          if (storeLink) {
            link = `${langPath}${storeLink}`
          }
        }
        break
      // 设计师招募页 [shein]
      case 'sheinxDesignerRecruit':
        link = `${langPath}/sheinx`
        break
      // 设计师详情页 [shein]
      case 'sheinxDesignerInformation':
        link = `${langPath}/designer/${itemHrefTarget}`
        break
      // [romwe] 折扣列表
      case 'promoDiscount':
        try {
          const PICKS_LINKS = this.context?.PICKS_GLOBAL_INFO?.LINKS || {}
          link = transformPromodiscountUrl(
            `${langPath}/promo-discount.html?hrefTarget=${itemHrefTarget}`,
            PICKS_LINKS
          )
        } catch(e) {
          console.log(e)
        }
        break
      // 礼品卡
      case 'giftCard':
        link = '/giftcard.html'
        break
      // 付费会员落地页
      case 'prime':
        link = '/user/prime'
        break
      // 一口价落地页
      case 'underPrice':
        link = `${langPath}/under-prices-products.html?tab=${itemHrefTarget}`
        break
      // 设计师列表页
      case 'sheinxDesignerList':
        link = `${langPath}/designer_list`
        break
      // LoverRomwe
      case 'loveromwe':
        link = `/looks-we-love-1.html`
        break
      // 优惠券中心
      case 'couponCenter':
        link = `/user/coupon`
        break
      // 个人中心 - 积分
      case 'pointsCenter':
        link = `/user/points`
        break
      // 个人信息编辑页
      case 'userInfoEdit':
        link = `/user/account_setting`
        break
      // 不贵频道页面
      case 'thriftyFind':
        link = `/super-deals`
        break
      default:
        if(this.sceneData?.pageType == 'homePage') {
          HomeSlslog.slsCompFilter({
            logLevel: 2,
            tag: 'client_home_warn_total',
            message: 'HomeRouterWarn',
            reason: 'clickUrlEmpty',
            module: this.propData?.styleType || '',
            tabName: this.sceneData?.tabName || '',
            pageName: this.sceneData?.pageType || '',
            cccId: this.propData?.componentId || '',
            position: 0,
          }, false)
        }
        break
    }
    return link || 'javascript:;'
  }
 
  /**
   * 获取CCC 完整的链接 - 包含链接参数等信息
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} cateLinks CCC 特殊链接的数据
   * @param {*} hotZoneParams 热区参数
   */
  getFullLink({
    item = {},
    cateLinks = {},
    compIndex,
    index,
    hotZoneParams = {},
  }) {
    let url = this.getLink({ item, cateLinks })
    // 外部链接直接跳 不拼其他参数
    if (item?.hrefType === 'webLink' && item?.isInternalLink == 0) return url
    // 服务端给一个纯链接
    if (typeof window === 'undefined') return url

    if (url.indexOf('javascript:;') > -1) return url

    return this.setLinkParams({ item, url, compIndex, index, hotZoneParams })
  }
 
  /**
   * TODO
   * 获取对应链接的参数
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} url 原链接
   * @param {*} hotZoneParams 热区信息
   */
  setLinkParams({ item, url, compIndex = 0, index = 0, hotZoneParams = {} }) {
    const params = []

    // 配置需开启的参数类型
    const paramsConfig = {
      // discountListParam: ['sheinPicks'],
      dailyParam: ['dailyNew', 'itemPicking'],
      activityParam: ['activityOld', 'activityNew'],
      userPathParam: ['real', 'sku', 'itemPicking', 'dailyNew', 'sheinPicks'],
      categoryParam: ['dailyNew', 'flashSale', 'lightningDeal'],
      storeCateParam: ['real', 'itemPicking'],
    }
    // mall信息
    if (item.mallCodes) params.push(`mallCode=${item.mallCodes}`)
    // Discount 参数
    // if (paramsConfig.discountListParam.includes(item.hrefType) && item.hrefTarget) params.push(`hrefTarget=${item.hrefTarget}`)
    // Activity 参数
    if (paramsConfig.activityParam.includes(item.hrefType)) {
      const activeUrlParams = this.activityUrlAddQuery(item)
      activeUrlParams && params.push(activeUrlParams)
    }
    // Category 参数
    if (paramsConfig.categoryParam.includes(item.hrefType) && item.categoryIds) {
      params.push(`cat_ids=${decodeURIComponent(item.categoryIds)}`)
    }
    // Category 页默认选中 Tab
    if (item.hrefType === 'category')
      params.push(`channelId=${item.hrefTarget}`)
    // dailynew 组件
    if (paramsConfig.dailyParam.includes(item.hrefType)) {
      item.daily && params.push(`daily=${item.daily}`)
      item.show_daily && params.push(`show_daily=${item.show_daily}`)
    }
    if (item.hrefType === 'sheinxDesignerInformation') {
      item.productPosKey && params.push(`productPosKey=${item.productPosKey}`)
    }
    // TODO 用户路径参数
    // if (paramsConfig.userPathParam.includes(item.hrefType)) {
    //   const userPathParam = this.getUserPathParam(url, item)
    //   userPathParam && params.push(userPathParam)
    // }
    // 店铺选品
    if(this.sceneData.pageFrom === 'storeHomePage' && paramsConfig.storeCateParam.includes(item.hrefType)){
      const recommend_context_params = encodeURIComponent(JSON.stringify({
        select_id: this.sceneData.storeSelectId,
        sc_url_id: this.sceneData.storeSelectId,
        service_type: 6,
      }))
      params.push(`recommend_page_type=storeSelectListPageRec&recommend_context_params=${recommend_context_params}&store_code=${this.sceneData.storeCode}`)
    }
    // adp
    if (item.adp) params.push(`adp=${item.adp}`)
    // top_cate_id
    if (item.top_cate_id) params.push(`top_cate_id=${item.top_cate_id}`)
    // 埋点参数
    // 店铺场景拓展 srcIdentifier
    let extraSrcIdentifier = ''
    if(this.sceneData?.pageFrom === 'storeHomePage' &&  this.sceneData?.storeCode){
      extraSrcIdentifier += `st=${this.sceneData.storeCode}`
    }
    const { url_params } = getBiCccSourceFrom({
      sceneName: this.sceneData?.sceneName, // 场景名称，同 channel name
      componentName: this.propData?.componentKey,
      operationComponentName: this.propData?.props?.name,
      comIndex: compIndex + 1, // 组件在整个渲染列表中的位置
      positionIndex: index + 1, // 当前item在组件中的位置
      item,
      hotZoneParams,
      activityId: this.sceneData?.activityId,
      activityNameEn: this.sceneData?.activityNameEn,
      pageFrom: this.sceneData?.pageFrom,
      extra: {
        extraSrcIdentifier,
      }
    })
    if (url_params) params.push(url_params)
    // ici
    const { name: iciName } = getGaCccParams({
      sceneName: this.sceneData?.sceneName, // 场景名称，同 channel name
      propData: this.propData,
      poskey: this.sceneData?.poskey,
      position: `PS=${compIndex + 1}-${index + 1}`,
    })
    if (iciName) params.push(`ici=${encodeURIComponent(iciName)}`)

    if (item?.hrefType === 'thirdPartyStoreList') {
      params.push(`tab=items`)
    }
    if (STYLE_TYPE_MAP[this.propData?.styleType]) {
      params.push(`styleType=${this.propData?.styleType}`)
    }
    // 专题sw标识
    if (item.hrefType === 'activityNew') {
      const { enableSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
      if (enableSceneMap.includes('cccx')) {
        params.push('proxy_enable=1')
      }
    }
    // 给板块 sbc 和 h1 添加参数 进行区分
    for (const key in blockIdentifiers) {
      if (blockIdentifiers[key].includes(this.propData?.blockKey) && this.sceneData.pageFrom === 'home') {
        params.push(`entranceType=${key}`)
      }
    }
    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${params.join('&')}`
  }
 
  /**
   * 活动页链接拼接 query 参数
   * @author songwei
   * @description wiki.pageId=283541755
   * @param {String} url                      跳转链接
   * @param {String} opt.hrefType             类型，activity=专题
   * @param {Object} opt.additionalConfig     待拼接的参数对象
   * @return {String}                         拼接完成的跳转链接
   */
  activityUrlAddQuery(opt = {}) {
    const { anchor, immersive } = opt.additionalConfig || {}
    const params = []
    if (immersive) {
      params.push('type=immersive')
    }
    if (anchor) {
      params.push(`anchor=${anchor}`)
    }
    return params.join('&')
  }

  // /**
  //  * 获取用户浏览路径
  //  * @param {*} url
  //  * @param {*} item
  //  * @returns
  //  */
  // getUserPathParam (url, item) {
  //   if (typeof window === 'undefined') return ''
  //   const { userPath } = window.SHEIN_COMMON
 
  //   if (userPath && Object.keys(userPath).length) {
  //     if (!userPath.getTitle(url)) return ''
 
  //     return [
  //       'srctype=homepage',
  //       // TODO
  //       // `userpath=${replaceHrefSpecChar({ href: `${this.navName}>${this.sceneName}>${item.title || item.content || userPath.getTitle(url)}` })}`
  //     ].join('&')
  //   }
 
  //   return ''
  // }
}
 
