import home from './home'
import products from './products'
import detail from './detail'
import categroy from './categroy'
import flashSale from './flashSale'
import cart from './cart'
import user from './user'
import order from './order'
import robot from './robot'
import campaigns from './campaigns'
import reviewCenter from './reviewCenter'
import orderReturn from './orderReturn'
import brandZone from './brandZone'
import storeComment from './storeComment'
import support from './support'
/**
 * @description 通过一下方式获取当前页面的pageName
 * console.log(window.SaPageInfo.page_name), 如果没有page_name, 则需要再相关html页面中添加。
 * */ 

export default [
  home,
  ...products,
  detail,
  ...categroy,
  flashSale,
  cart,
  ...user,
  ...order,
  robot,
  campaigns,
  reviewCenter,
  orderReturn,
  brandZone, // 品牌频道
  storeComment,
  support,
  {
    pageName: 'page_payment_successful',
    newPosKeys: ['RememberCardsSuccess'],
    posKeys: ['PaySuccess', 'PaymenSuccessRecommend', 'Subscribe'],
  },
  {
    pageName: 'page_checkout',
    newPosKeys: [],
    /**
     * PromotionIcon 推全 ShowPromotionIcon
     */
    // posKeys: ['CheckoutCouponProcess'],
  },
  {
    pageName: 'page_return_item_new',
    newPosKeys: [],
  }
].map(item => {
  item.routeName = item.pageName
  return item
})
